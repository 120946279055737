import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const About = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Layout bodyClass="page-services">
      <SEO title={t("เกี่ยวกับเรา")} />
      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12 col-md-8">
            <h1 className="title">{t("เกี่ยวกับเรา")}</h1>
            <div className="content">
              <h1>
                {t("บริษัท เอ็น.อาร์.เอฟ.เพาเดอร์โค้ทติ้ง จำกัด")}
                <br />
              </h1>
              <p>
                {t("รับงานพ่นสีฝุ่นอบสีโลหะได้ทุกรูปแบบ")}
                {t(
                  "สามารถรับชิ้นงานขนาดใหญ่ได้สูงสุด 2 x 4.9 x 2 ม. ด้วยคุณภาพและตรงต่อเวลา มาตรฐานที่บริษัทอุตสาหกรรมขนาดใหญ่ไว้วางใจ"
                )}
              </p>
              <p>
                {t(
                  "บริการรับพ่นสีพาวเดอร์โค้ท ชิ้นงานโลหะทุกชนิด ทั้งเหล็ก, อลูมิเนียม,สแตนเลส,โลหะชุบซิงค์ฯ ให้กับงานอุตสาหกรรม ชิ้นส่วนรถยนต์ เฟอร์นิเจอร์ พาร์ทอุตสาหกรรมยานยนต์"
                )}
              </p>
              <p>
                <img src="/images/about/banner.jpg" alt="banner nrf" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default About;
